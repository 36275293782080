import BaseKeystoneEntity from '@/models/Keystone_v2/BaseKeystoneEntity'

class FeeRateModel extends BaseKeystoneEntity {
  constructor (data = {}) {
    super(data)
    this.id = data.id
    this.feeType = data.fee_type
    this.tierConditions = data.tier_conditions
    this.saveOriginal()
  }
}

export default FeeRateModel
