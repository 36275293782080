import BaseKeystoneEntity from '@/models/Keystone_v2/BaseKeystoneEntity'

class ContractModel extends BaseKeystoneEntity {
  constructor (data = {}) {
    super(data)
    this.id = data.id
    this.contractNumber = data.contract_number
    this.contractRepository = data.contract_repository
    this.startDate = data.start_date
    this.endDate = data.end_date
    this.active = data.active
    this.dvContractRepository = data.dv_contract_repository
    this.companyThatSignedTheContract = data.company_that_signed_the_contract ? data.company_that_signed_the_contract : 'scibids'
    this.saveOriginal()
  }
}

export default ContractModel
