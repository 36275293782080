import BaseKeystoneEntity from '@/models/Keystone_v2/BaseKeystoneEntity'
import CompanyModel from '@/models/Keystone_v2/CompanyModel'

class SiegeModel extends BaseKeystoneEntity {
  constructor (data = {}) {
    super(data)
    this.id = data.id
    this.name = data.name
    this.company = new CompanyModel(data.company ? data.company : {})
    this.saveOriginal()
  }
}

export default SiegeModel
