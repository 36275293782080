import BaseKeystoneEntity from '@/models/Keystone_v2/BaseKeystoneEntity'
import BillingAccountModel from '@/models/Keystone_v2/BillingAccountModel'

class MetaHoldingModel extends BaseKeystoneEntity {
  constructor (data = {}) {
    super(data)
    this.id = data.id
    this.name = data.name
    this.billingAccount = new BillingAccountModel(data.billing_account ? data.billing_account : {})
    this.saveOriginal()
  }
}

export default MetaHoldingModel
