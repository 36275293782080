import BaseKeystoneEntity from '@/models/Keystone_v2/BaseKeystoneEntity'
import HoldingModel from '@/models/Keystone_v2/HoldingModel'
import BillingAccountModel from '@/models/Keystone_v2/BillingAccountModel'

class CompanyModel extends BaseKeystoneEntity {
  constructor (data = {}) {
    super(data)
    this.id = data.id
    this.name = data.name
    this.holding = new HoldingModel(data.holding ? data.holding : {})
    this.country = data.country
    this.billingAccount = new BillingAccountModel(data.billing_account ? data.billing_account : {})
    this.hideInsight = data.hide_insight
    this.hasAutoplugs = data.has_autoplugs
    this.saveOriginal()
  }
}

export default CompanyModel
