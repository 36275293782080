import BaseKeystoneEntity from '@/models/Keystone_v2/BaseKeystoneEntity'
import ContractModel from '@/models/Keystone_v2/ContractModel'
import BillingEntityModel from '@/models/Keystone_v2/BillingEntityModel'
import RatecardModel from '@/models/Keystone_v2/RatecardModel'

class BillingAccountModel extends BaseKeystoneEntity {
  constructor (data = {}) {
    super(data)
    this.id = data.id
    this.msaType = data.msa_type
    this.active = data.active
    this.contract = new ContractModel(data.contract ? data.contract : {})
    this.billingEntity = new BillingEntityModel(data.billing_entity ? data.billing_entity : {})
    this.ratecard = new RatecardModel(data.ratecard ? data.ratecard : {})
    this.saveOriginal()
  }
}

export default BillingAccountModel
