import BaseKeystoneEntity from '@/models/Keystone_v2/BaseKeystoneEntity'
import FeeRateModel from '@/models/Keystone_v2/FeeRateModel'

class RatecardModel extends BaseKeystoneEntity {
  constructor (data = {}) {
    super(data)
    this.id = data.id
    this.hasTrialPeriod = data.has_trial_period
    this.trialPeriodEndDate = data.trial_period_end_date
    this.expectedDateOfRatecardChange = data.expected_date_of_ratecard_change
    this.ratecardCurrency = data.ratecard_currency
    this.minBilledOptimizedMediaCost = data.min_billed_optimized_media_cost
    this.billEvenWhenNoMediaCost = data.bill_even_when_no_media_cost
    this.feeRateOnAdditionalMargin = data.fee_rate_on_additional_margin
    this.accomodationCostPerDay = data.accomodation_cost_per_day
    this.rebateMode = data.rebate_mode
    this.ongoingCredit = data.ongoing_credit
    this.feeRate = new FeeRateModel(data.fee_rate ? data.fee_rate : {})
    this.saveOriginal()
  }
}

export default RatecardModel
