import BaseKeystoneEntity from '@/models/Keystone_v2/BaseKeystoneEntity'
import BillingAccountModel from '@/models/Keystone_v2/BillingAccountModel'
import MetaHoldinModel from '@/models/Keystone_v2/MetaHoldingModel'

class HoldingModel extends BaseKeystoneEntity {
  constructor (data = {}) {
    super(data)
    this.id = data.id
    this.name = data.name
    this.hasFitScore = data.has_fit_score != null ? !!data.has_fit_score : true
    this.hasInsight = !!data.has_insight
    this.metaHolding = new MetaHoldinModel(data.meta_holding ? data.meta_holding : {})
    this.billingAccount = new BillingAccountModel(data.billing_account ? data.billing_account : {})
    this.saveOriginal()
  }
}

export default HoldingModel
