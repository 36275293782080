import BaseKeystoneEntity from '@/models/Keystone_v2/BaseKeystoneEntity'
import { BillingEntityApiArgs, IBillingEntityCountry, IBillingEntitySaleInCharge } from 'types/billing_account_types'

export const DEFAULT_VALUE_PAYMENT_TERM_DV360 = 30
export const DEFAULT_VALUE_PAYMENT_TERM = 60

class BillingEntityModel extends BaseKeystoneEntity {
  id: number
  name: string
  nickname: string
  nameOfRecipient: string
  upflowCustomerId: string
  odooCustomerId: string
  companyRegistrationNumber: string
  vatNumber: string
  firstLineCurrency: string
  invoiceCurrency: string
  paymentTerm: number
  invoiceDataGrouping: 'BILLING_ENTITY' | 'ADVERTISER' | 'SOW' | 'CUSTOM'
  address: string
  zipcode: string
  city: string
  country: IBillingEntityCountry
  countryId: number
  reference: string
  scibidsRib: 'hsbc_dv'
  invoiceTo: Array<string>
  invoiceCc: Array<string>
  invoiceBcc: Array<string>
  driveRepository: string
  isBroughtByReseller: boolean
  resellerEmailAddress: string
  invoiceAttachmentMode: 'NONE' | 'CC' | 'BCC'
  typology: string
  saleInCharge: IBillingEntitySaleInCharge
  saleInChargeId: number
  dv360TcUpdateContactEmail: string

  constructor (data: BillingEntityApiArgs = {}) {
    super(data)
    this.id = data.id
    this.name = data.name
    this.nickname = data.nickname
    this.nameOfRecipient = data.name_of_recipient
    this.upflowCustomerId = data.upflow_customer_id
    this.odooCustomerId = data.odoo_customer_id
    this.companyRegistrationNumber = data.company_registration_number
    this.vatNumber = data.vat_number
    this.firstLineCurrency = data.first_line_currency
    this.invoiceCurrency = data.invoice_currency
    this.paymentTerm = data.payment_term
    this.invoiceDataGrouping = data.invoice_data_grouping
    this.address = data.address
    this.zipcode = data.zipcode
    this.city = data.city
    this.country = data.country
    this.countryId = data.country_id
    this.reference = data.reference
    this.scibidsRib = data.scibids_rib
    this.invoiceTo = data.invoice_to
    this.invoiceCc = data.invoice_cc
    this.invoiceBcc = data.invoice_bcc
    this.driveRepository = data.drive_repository
    this.isBroughtByReseller = data.is_brought_by_reseller
    this.resellerEmailAddress = data.reseller_email_address
    this.invoiceAttachmentMode = data.invoice_attachment_mode
    this.typology = data.typology
    this.saleInCharge = data.sale_in_charge
    this.saleInChargeId = data.sale_in_charge_id
    this.dv360TcUpdateContactEmail = data.dv360_tc_update_contact_email
    this.saveOriginal()
  }

  getProp (key: string) {
    return this[key as keyof BillingEntityModel]
  }

  setProp (key: string, value: any) {
    (this as any)[key as string] = value
  }

  resetMails () {
    this.invoiceTo = []
    this.invoiceCc = []
    this.invoiceBcc = []
  }

  fillForPartnership (partnershipName: string, holdingName: string) {
    switch (partnershipName) {
      case 'XANDR_PARTNERSHIP':
        return this.fillForXandrPartnership(holdingName)
      case 'TTD_PARTNERSHIP':
        return this.fillForTTDPartnership(holdingName)
      case 'DV360_PARTNERSHIP':
        return this.fillForDV360Partnership(holdingName)
      default:
        break
    }
  }

  fillForXandrPartnership (entityName: string) {
    if (this.name == null || this.name === '') {
      this.resetMails()
      this.name = 'Xandr Partnership (' + entityName + ')'
      this.nickname = 'Xandr Partnership (' + entityName + ')'
      this.nameOfRecipient = 'Xandr Inc'
      this.firstLineCurrency = 'USD'
      this.invoiceCurrency = 'USD'
      this.paymentTerm = DEFAULT_VALUE_PAYMENT_TERM
      this.invoiceDataGrouping = 'BILLING_ENTITY'
      this.address = 'Xandr Inc. 28 West 23rd Street 4th Floor'
      this.zipcode = '10010'
      this.city = 'New York'
      this.countryId = 235
      this.scibidsRib = 'hsbc_dv'
      this.invoiceTo = ['billing@scibids.com']
      this.dv360TcUpdateContactEmail = null
    }
  }

  fillForTTDPartnership (entityName: string) {
    if (this.name == null || this.name === '') {
      this.resetMails()
      this.name = 'TTD Partnership (' + entityName + ')'
      this.nickname = 'TTD Partnership (' + entityName + ')'
      this.nameOfRecipient = 'The Trade Desk'
      this.firstLineCurrency = 'USD'
      this.invoiceCurrency = 'USD'
      this.paymentTerm = DEFAULT_VALUE_PAYMENT_TERM
      this.invoiceDataGrouping = 'BILLING_ENTITY'
      this.address = 'Floor 10, One Bartholomew Close Barts Square'
      this.zipcode = 'EC1A7BL'
      this.city = 'London'
      this.countryId = 234
      this.scibidsRib = 'hsbc_dv'
      this.invoiceTo = ['AP.UK@thetradedesk.com']
      this.dv360TcUpdateContactEmail = null
    }
  }

  fillForDV360Partnership (entityName: string) {
    if (this.name == null || this.name === '') {
      this.resetMails()
      this.name = 'Google Partnership (' + entityName + ')'
      this.nickname = 'Google Partnership (' + entityName + ')'
      this.nameOfRecipient = 'Google Inc'
      this.firstLineCurrency = 'USD'
      this.invoiceCurrency = 'USD'
      this.paymentTerm = DEFAULT_VALUE_PAYMENT_TERM_DV360
      this.invoiceDataGrouping = 'BILLING_ENTITY'
      this.address = 'Gordon House Barrow Street'
      this.zipcode = '4'
      this.city = 'Dublin'
      this.countryId = 109
      this.scibidsRib = 'hsbc_dv'
      this.invoiceTo = ['billing@scibids.com']
      this.dv360TcUpdateContactEmail = null
    }
  }
}

export default BillingEntityModel
